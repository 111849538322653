import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/planung',
    name: 'resource-planner.public',
    component: () => import('./pages/PlannerOverview.vue'),
    meta: { auth: true },
  },
  {
    path: '/einsatzplaner/planung',
    name: 'ResourceplannerPublicView',
    component: () => import('./pages/PublicView.vue'),
    meta: { auth: 'optional' },
  },
  {
    path: '/resources/:resourceId/fields',
    name: 'ResourceMap',
    props: true,
    meta: { auth: 'optional' },
    component: () => import('./pages/ResourceMap.vue'),
  },

  {
    path: '/resource-planner/plan',
    name: 'resource-planner.index',
    component: () => import('./pages/ResourcePlanner.vue'),
    meta: { auth: true },
  },
  {
    path: '/resource-planner/distribute-hours',
    name: 'DistributeHours',
    component: () => import('./pages/DistributeHours.vue'),
    meta: { auth: true },
  },
  {
    path: '/resource-planner/tools',
    name: 'Tools',
    component: () => import('./pages/ToolsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/resource-planner/cars',
    name: 'Cars',
    component: () => import('./pages/CarsIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/vorplaner',
    name: 'pre-planner.index',
    component: () => import('./components/pre-planner/PrePlannerIndexPage.vue'),
    meta: { auth: true },
  },
]
